import { lazy } from "react";
import { Navigate } from "react-router-dom";

import AuthGuard from "./auth/AuthGuard";
import { Loadable } from "./components";
import MatxLayout from "./components/MatxLayout/MatxLayout";
import sessionRoutes from "./views/sessions/session-routes";
import administrationRoutes from "app/views/administration/AdministrationRoutes"
import userRoutes from "app/views/user/UserRoutes"
//import { Dashboard } from "@mui/icons-material";


// DASHBOARD PAGE
const Dashboard = Loadable(lazy(() => import("app/views/dashboard/Dashboard")));

    const routes = [
    {
        path: "/",
        element: <Navigate to="dashboard" />
    },
    {
        path: "*",
        element: <Navigate to="dashboard" />
    },
    {
        element: (
            <AuthGuard>
                <MatxLayout />
            </AuthGuard>
        ),
        children: [
            ...administrationRoutes,
            ...userRoutes,

            {
                path: "/dashboard",
                element: <Dashboard />,
            },
        ]
    },

    // session pages route
    ...sessionRoutes
    ];

export default routes;
