export const navigations = [
  // Dashboard
    {
        name: 'Dashboard',
        path: '/dashboard',
        icon: 'dashboard',
        auth: ['admin', 'user'],
    },

    // Clients
    {
        label: 'Clients',
        type: 'label',
        auth: ['user', 'admin'],
    },
    {
        name: 'Client List',
        icon: 'people',
        path: '/client',
        auth: ['user'],
    },

    {
        name: 'Investment Summary',
        icon: 'price_change',
        path: '/investment-summary',
        auth: ['user'],
    },

    {
        name: 'Import Investment',
        path: '/client/import-investment',
        auth: ['admin'],
    },

    //Administration
    {
        label: 'Administration',
        type: 'label',
        auth: ['admin'],
    },
    {
        name: 'Users',
        path: '/admin/user',
        auth: ['admin'],
    },
    {
        name: 'Company',
        path: '/admin/company',
        auth: ['admin'],
    },

    // Portfolio
    {
        label: 'Portfolio',
        type: 'label',
        auth: ['admin'],
    },
    {
        name: 'Porfolio Types',
        path: '/portfolio/type',
        auth: ['admin'],
    },
    {
        name: 'Porfolio Category',
        path: '/portfolio/category',
        auth: ['admin'],
    },
    {
        name: 'Risk Profile',
        path: '/portfolio/riskprofile',
        auth: ['admin'],
    },

    // Funds
    {
        label: 'Funds',
        type: 'label',
        auth: ['admin', 'user'],
    },
    {
        name: 'Government Tax',
        path: '/fund/tax',
        auth: ['admin'],
    },
    {
        name: 'Fund Provider',
        path: '/fund/provider',
        auth: ['admin'],
    },
    {
        name: 'Fund Category',
        path: '/fund/category',
        auth: ['admin'],
    },
    {
        name: 'Asset Class',
        path: '/fund/assetclass',
        auth: ['admin'],
    },
    {
        name: 'Fund List',
        path: '/fund/fund',
        auth: ['admin'],
    },
    {
        name: 'Fund Price',
        icon: 'account_balance_wallet',
        path: '/fundprice',
        auth: ['user'],
    },
    {
        name: 'Fund Performance',
        path: '/fundperformance',
        icon: 'trending_up',
        auth: ['user'],
    },


    {
        name: 'Fund Performance',
        path: 'fund/fundperformance',
        auth: ['admin'],
    },

    // Fund Price
    {
        label: 'Fund Prices',
        type: 'label',
        auth: ['admin'],
    },
    {
        name: 'Fund Price',
        path: '/fundprice/fundprice',
        auth: ['admin'],
    },
    {
        name: 'Date Format',
        path: '/fundprice/dateformat',
        auth: ['admin'],
    },
    {
        name: 'Import Price',
        path: '/fundprice/importprice',
        auth: ['admin'],
    },
    {
        name: 'Price Scraper',
        path: '/fundprice/scraper',
        auth: ['admin'],
    },
    {
        name: 'Update Price',
        path: '/fundprice/updateprice',
        auth: ['admin'],
    },
    {
        name: 'Scraper Logs',
        path: '/fundprice/scraperlogs',
            auth: ['admin'],
    },  
    {
        name: 'Tasks Logs',
        path: '/fundprice/tasklogs',
        auth: ['admin'],
    }, 

    // EPF
    {
        label: 'EPF',
        type: 'label',
        auth: ['user'],
    },
    {
        name: 'Calculator',
        icon: 'calculate',
        path: '/epf',
        auth: ['user'],
    },
]