import { lazy } from 'react'
import { Loadable } from 'app/components';


const AppClient = Loadable(lazy(() => import("./client/client/Clients")))
const AppPortfolio = Loadable(lazy(() => import("./client/portfolio/Portfolio")))
const AppTransaction = Loadable(lazy(() => import("./client/portfolio-transaction/Transaction")))
const AppTransactionInvestment = Loadable(lazy(() => import("./client/portfolio-transaction-investment/TransactionInvestment")))
const AppTransactionInvestmentdetail = Loadable(lazy(() => import("./client/portfolio-transaction-investment-investmentdetail/TransactionInvestmentdetail")))
const AppLedger = Loadable(lazy(() => import("./client/portfolio-ledger/Ledger")))
const AppLedgerInvestment = Loadable(lazy(() => import("./client/portfolio-ledger-investment/LedgerInvestment")))
const AppLedgerInvestmentdetail = Loadable(lazy(() => import("./client/portfolio-ledger-investment-investmentdetail/LedgerInvestmentdetail")))
const AppFundPrice = Loadable(lazy(() => import("./fundprice/FundPrice")))
const AppFundPriceDetail = Loadable(lazy(() => import("./fundprice/FundPriceDetail")))
const AppFundPerformance = Loadable(lazy(() => import("./fundperformance/FundPerformance")))
const AppEPF = Loadable(lazy(() => import("./epf/EPF")))
const AppProfile = Loadable(lazy(() => import("./profile/Profile")))
const AppInvestmentSummary = Loadable(lazy(() => import("./client/investment-summary/InvestmentSummary")))


const clientRoutes = [
    {
        path: '/profile',
        exact: true,
        element: <AppProfile />,
    },
    {
        path: '/client',
        exact: true,
        element: <AppClient />,
    },
    {
        path: '/investment-summary',
        exact: true,
        element: <AppInvestmentSummary />,
    },
    {
        path: '/client/portfolio',
        exact: true,
        element: <AppPortfolio />,
    },
    {
        path: '/client/portfolio/transaction',
        exact: true,
        element: <AppTransaction />,
    },
    {
        path: '/client/portfolio/transaction/investment',
        exact: true,
        element: <AppTransactionInvestment />,
    },
    {
        path: '/client/portfolio/transaction/investment/investmentdetail',
        exact: true,
        element: <AppTransactionInvestmentdetail />,
    },
    {
        path: '/client/portfolio/ledger',
        exact: true,
        element: <AppLedger />,
    },
    {
        path: '/client/portfolio/ledger/investment',
        exact: true,
        element: <AppLedgerInvestment />,
    },
    {
        path: '/client/portfolio/ledger/investment/investmentdetail',
        exact: true,
        element: <AppLedgerInvestmentdetail />,
    },
    {
        path: '/fundprice',
        exact: true,
        element: <AppFundPrice />,
    },
    {
        path: '/fundprice/detail',
        exact: true,
        element: <AppFundPriceDetail />,
    },
    {
        path: '/fundperformance',
        exact: true,
        element: <AppFundPerformance />,
    },
    {
        path: '/epf/',
        exact: true,
        element: <AppEPF />,
    },
]

export default clientRoutes