import { createSlice } from "@reduxjs/toolkit"
import { navigations } from 'app/navigations'


const initialState = {
    value : [...navigations]
}

const getfilteredNavigations = (navList = [], permissions) => {
    return navList.reduce((array, nav) => {
        if (nav.auth) 
        {

            if (permissions.some(item => nav.auth.includes(item)))
            {
                array.push(nav)
            }
        } 
        else 
        {
            if (nav.children) 
            {
                nav.children = getfilteredNavigations(nav.children, permissions)
                array.push(nav)
            } 
            else 
            {
                array.push(nav)
            }
        }
        return array
    }, [])
}

const navigationSlice = createSlice({
    name: "navigation",
    initialState,
    reducers: {
        setUserNavigation: (state, action) => {
            state.value = getfilteredNavigations(initialState.value, action.payload)
        }
    }
})

export const { setUserNavigation } = navigationSlice.actions
export default navigationSlice.reducer